import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import { Row, Col, Typography } from 'antd';

const { Paragraph } = Typography;

const AllImages = () => {
  const data = useStaticQuery(graphql`
  {
    allImageSharp {
      edges {
        node {
          fixed(width: 400) {
            src
            srcSet
            aspectRatio
            width
            height
          }
          fluid(maxWidth: 880, fit: COVER) {
            presentationWidth
            presentationHeight
            src
            srcSet
            aspectRatio
          }
          gatsbyImageData
          id
          original {
            src
            height
            width
          }
        }
      }
    }
  }
  `)
  return (
    <Row className='all_images fullwidth' key="58585" >
      {
        data.allImageSharp.edges.map(ImageNode =>  {
          const imageNode = ImageNode.node
          const imagePath = imageNode.fixed
          const originalImage = imageNode.original
          const mdThumb = `![markdown](${imagePath.src})`
          const fullWidth =  `![altText](${ImageNode.node.gatsbyImageData.images.fallback.src})`
          const mdOriginal = `![markdown](${originalImage.src})`

          const key = ImageNode.node.id

          return (
              <Col key={key} style={{ display: `inline`}}>
                  <Img
                  fixed={imagePath}
                  alt="this"
                  style={{
                      width: `230px`, 
                      height: `230px`
                  }}
                  />
                  <Paragraph copyable={{ text: mdThumb }}style={{ fontSize: `10px`}}><em>thumb: 400 X 400</em></Paragraph>
                  <Paragraph copyable={{ text: imagePath.src }}style={{ fontSize: `10px`}}><em>Thumb src</em></Paragraph>
                  <Paragraph copyable={{ text: mdOriginal }} style={{ fontSize: `10px`}}><em>orginal: {originalImage.width} X {originalImage.height}</em> </Paragraph>
                  <Paragraph copyable={{ text: fullWidth }}style={{ fontSize: `10px`}}><em>Fullwidth src</em></Paragraph>
                  <Paragraph copyable={{ text: originalImage.src }}style={{ fontSize: `10px`}}><em>Original src</em></Paragraph>
              </Col>
          )
        })
      }
    </Row>
  )
}

export default AllImages